import * as React from 'react';

interface ImageHeroProps {
	bgImage: string;
	h1: string;
	h2: string;
}

const ImageHero2: React.FC<ImageHeroProps> = (props: ImageHeroProps) => {
	return (
		<section
			style={{ backgroundImage: `url('${props.bgImage}')` }}
			className={'bg-cover bg-top px-3 py-14'}
		>
			<div
				className={
					'container mx-auto flex h-[600px] flex-col justify-end text-center max-md:h-[450px] max-md:justify-center'
				}
			>
				<h1
					className={
						'mx-auto max-w-5xl font-rubik text-6xl text-white max-md:text-4xl'
					}
				>
					{props.h1}
				</h1>
				<h2
					className={
						'mx-auto max-w-3xl pb-36 font-rubik text-2xl font-medium text-white'
					}
				>
					{props.h2}
				</h2>
			</div>
		</section>
	);
};

export default ImageHero2;
