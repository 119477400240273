import * as React from 'react';

// @ts-ignore
import icon from '../../images/wellness-icon.png';
const WellnessIcon: React.FC = () => {
	return (
		<section
			className={
				'absolute mt-[-54px] flex w-full flex-row justify-center'
			}
		>
			<img width={108} src={icon} alt={'Wellness Icon'} />
		</section>
	);
};

export default WellnessIcon;
