import * as React from 'react';
const BestPeopleSection: React.FC = () => {
	return (
		<section className={'px-3'}>
			<div className={'container mx-auto flex flex-col text-center'}>
				<h2 className={'py-6 font-rubik text-4xl text-[#333333]'}>
					We are looking for the best people.
				</h2>
			</div>
		</section>
	);
};

export default BestPeopleSection;
