import * as React from 'react';

// @ts-ignore
import benefitCardImg1 from '../../images/benefit-card-1.svg';
// @ts-ignore
import benefitCardImg2 from '../../images/benefit-card-2.svg';
// @ts-ignore
import benefitCardImg3 from '../../images/benefit-card-3.svg';

const BenefitCards: React.FC = () => {
	const cards = [
		{
			imgSrc: benefitCardImg1,
			title: 'MEDICAL',
			p: 'We offer 5 levels of insurance plans from Blue Cross Blue Shield specifically designed to ensure our employees have medical coverage that fits their needs.',
			features: [
				'FREE 24/7 Virtual Doctor Visits',
				'Wellness Discounts',
				'FREE Annual Physicals',
				'Flexible Spending Account (FSA)'
			]
		},
		{
			imgSrc: benefitCardImg2,
			title: 'DENTAL',
			p: 'Our standard and premium dental plans from Blue Cross Blue Shield cover dental and orthodontia services.',
			features: [
				'Standard Dental Plan',
				'Premium Dental Plan',
				'Orthodontia Coverage'
			]
		},
		{
			imgSrc: benefitCardImg3,
			title: 'VISION',
			p: 'Our vision plan through National Vision Administrators (NVA) allows employees to get both contact lenses or frames each year!',
			features: [
				'Contact Lenses & Frames',
				'Prescription Safety Glasses',
				'Hearing Aid Discount Included'
			]
		},
		{
			imgSrc: benefitCardImg1,
			title: 'PAID TIME OFF',
			p: 'We care about our team’s work-life balance through all seasons of their lives.',
			features: [
				'Competitive Paid Time Off Program',
				'Paid Parental Leave Program',
				'Graduated Return to Work Program for New Parents'
			]
		},
		{
			imgSrc: benefitCardImg1,
			title: 'RETIREMENT',
			p: 'We are committed to ensuring our employees will be prepared for retirement when the time comes.',
			features: [
				'401(K) with Company Match',
				'Roth & Traditional Contribution Options',
				'Auto Enrollment for New Hires',
				'Free Financial Resources'
			]
		},
		{
			imgSrc: benefitCardImg1,
			title: 'BONUS BENEFITS',
			p: 'We are proud to offer these additional benefits to our employees.',
			features: [
				'Company Paid Leave Insurance',
				'Short Term & Long Term Disability',
				'Employee Assistance Program'
			]
		}
	];

	return (
		<section>
			<div className={'w-full bg-bt-blue px-5'}>
				<div
					className={
						'container mx-auto flex flex-row justify-center text-center text-white'
					}
				>
					<h2 className={'pt-20 pb-40 font-rubik text-4xl'}>
						We offer diverse benefits for <br /> a diverse workforce
					</h2>
				</div>
			</div>
			<div className={'w-full bg-bt-light-gray px-5 pb-16'}>
				<div
					className={
						'container mx-auto flex flex-row flex-wrap justify-center gap-5'
					}
					style={{ transform: 'translateY(-50px)' }}
				>
					{cards.map((card) => {
						return (
							<div
								className={
									'flex min-w-[360px] max-w-[360px] flex-col items-center rounded-xl bg-white p-8 pb-20 text-center shadow max-sm:min-w-0 max-sm:max-w-full'
								}
							>
								<img src={card.imgSrc} />
								<h3 className={'text-gray font-rubik text-2xl'}>
									{card.title}
								</h3>
								<p
									className={
										'text-gray mt-8 pb-6 font-rubik text-xl'
									}
								>
									{card.p}
								</p>
								{card.features.map((feature) => {
									return (
										<span
											className={
												'text-gray text-md py-1 font-rubik'
											}
										>
											{feature}
										</span>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default BenefitCards;
