import * as React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
import PageIntroSection from '../../components/PageIntroSection/PageIntroSection';
import ImageHero2 from '../../components/ImageHero2/ImageHero2';

// @ts-ignore
import heroImage from '../../images/benefits-bg.png';
import BestPeopleSection from '../../components/BestPeopleSection/BestPeopleSection';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';
import { graphql, useStaticQuery } from 'gatsby';
import WellnessIcon from '../../components/WellnessIcon/WellnessIcon';
import BenefitCards from '../../components/BenefitCards/BenefitCards';

const BenefitsPage: React.FC = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<ImageHero2
				bgImage={heroImage}
				h1={'Be rewarded for your hard work'}
				h2={'Our team enjoys more than just a job at Belle Tire.'}
			/>
			<WellnessIcon />
			<PageIntroSection
				h2={'WE CARE ABOUT WELLNESS'}
				white
				pTags={[
					{
						text: 'Belle Tire is proud to offer excellent health plans designed to protect our employees and their families. We take pride in being more flexible than other companies, offer support to a diverse population and want to make sure that our team and their families, don’t have to worry about the unexpected!'
					}
				]}
			/>
			<BenefitCards />
			<JobAlerts />
			<BestPeopleSection />
			<JobSearchForm data={data} />
		</Layout>
	);
};

export default BenefitsPage;
